class reportTableViewSubRowComponent extends Component {

    static name() {
        return "reportTableViewSubRow";
    }

    static componentName() {
        return "reportTableViewSubRow";
    }


    getProps() {
        return ['row','columns','headings'];
    }


    getComputed() {
        return {
            myoptions:function(){
                console.log('Options ',this.headings)
                return {
                    filterable: false,
                    columnsDropdown: false,
                    preserveState: false,
                    headings: this.headings,
                    uniqueKey:'internalId',
                    rowClassCallback: function(row){
                        //console.log('.rowClassCallback',row.is_footer);
                        if(row.is_footer){
                            console.log('row ok ');
                            return 'footer-row';
                        }
                        return false;
                    }
                }
            },
            rows:function () {
                console.log('Calculated SubRows',this);
                return this.row.$subrows;
            }
        };
    }

    getTemplate() {
        return `<v-client-table :columns="columns" :data="rows" :options="myoptions"  ></v-client-table>`;
    }
}

reportTableViewSubRowComponent.registerComponent();